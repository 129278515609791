<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady">
      <div v-if="screenshotUrl" class="" style="position:relative">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="max-height:80vh" />
        </div>
        <div class="text-center">
          <strong>
            长按图片保存或分享给朋友
          </strong>          <!--<button>重置位置</button>-->
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="modelHide">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div v-else-if="screenshotError" class="" style="max-width:280px">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="width:100%" />
        </div>
        <div class="">
          <strong>
            <a href="https://panorover.com/B/tQ6" target="_blank">
              说明：看到本信息说明您的安卓微信不支持本功能，请点击右上角“…”“在浏览器打开”后生成二维码分享海报。
            </a>
          </strong>
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="modelHide">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div v-else>请稍侯</div>
    </component>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        screenshotUrl: null,
        screenshotError: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          showScreenshot: this.showScreenshot,
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
          this.showScreenshot()
        } else {
          alert('模态框还没有准备好')
        }
      },
      //#region 截图相关
      getSvgObj(html, width, height) {
        return `
  <svg xmlns="http://www.w3.org/2000/svg" width="${width || 1000}" height="${height || 1000}">
    <foreignObject width="100%" height="100%">
      <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:12px">
       ${html}
      </div>
    </foreignObject>
  </svg>
`
      },
      htmlToBase64(html, width, height) {
        return new Promise((resolve, reject) => {
          let obj = this.getSvgObj(html, width, height)
          //resolve(`data:image/svg+xml;base64,${window.btoa(encodeURI(obj))}`)
          let svg = new Blob([obj], { type: 'image/svg+xml;charset=utf-8' })
          var blob = svg
          var fileReader = new FileReader()
          fileReader.onloadend = function (e) {
            var result = e.target.result
            resolve(result)
          }
          fileReader.readAsDataURL(blob)
        })
      },
      htmlToSvgXml(html, width, height) {
        return new Promise((resolve, reject) => {
          let obj = this.getSvgObj(html, width, height)
          resolve(`data:image/svg+xml;charset=utf-8,${obj}`)
        })
      },
      getURLBase64(url) {
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest()
          xhr.open('get', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (this.status === 200) {
              var blob = this.response
              var fileReader = new FileReader()
              fileReader.onloadend = function (e) {
                var result = e.target.result
                resolve(result)
              }
              fileReader.readAsDataURL(blob)
            }
          }
          xhr.onerror = function (err) {
            reject(err)
          }
          xhr.send()
        })
      },
      showScreenshot2() {
        var view = this.getView()
        var url = `${location.protocol}//${location.host}${location.pathname}?view.fov=${view.fov}&view.hlookat=${view.hlookat}&view.vlookat=${view.vlookat}`
        var qrurl = `${process.env.baseUrl}/Api/QRCode/?url=${encodeURIComponent(url)}&bColor=0x00FFFFFF&fColor=0xFF000000&level=2`
        var ps = []
        var screenshot, qrCodeImg, avatarImg, userNickName, title, description
        ps.push(
          new Promise((resolve, reject) => {
            this.krpano_makeScreenshot((img) => {
              screenshot = img
              resolve()
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64(qrurl).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                qrCodeImg = image
                resolve()
              }
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64((this.pano.user.avatarUrl || '/images/avatar-default.jpg').replace('/0/0/0/0/', '/200/200/1/0/')).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                avatarImg = image
                resolve()
              }
            })
          })
        )
        //ps.push(new Promise((resolve, reject) => {
        //  //var element = document.createElement('div')
        //  //element.innerHTML = `<div style='width: 100px;font-size:12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center;'>${this.pano.user.nickName}</div>`
        //  var element = document.getElementById('nickName')
        //  domtoimage.toPixelData(element).then(function (pixels) {
        //    var canvas = document.createElement('canvas')
        //    var context = canvas.getContext('2d')
        //    canvas.width = 50
        //    canvas.height = 400
        //    context.clearRect(0, 0, 50, 400)
        //    for (var y = 0; y < element.scrollHeight; ++y) {
        //      for (var x = 0; x < element.scrollWidth; ++x) {
        //        var pixelAtXYOffset = (4 * y * element.scrollHeight) + (4 * x)
        //        /* pixelAtXY is a Uint8Array[4] containing RGBA values of the pixel at (x, y) in the range 0..255 */
        //        var pixelAtXY = pixels.slice(pixelAtXYOffset, pixelAtXYOffset + 4)
        //        context.fillStyle = `rgba(${pixelAtXY[0]},${pixelAtXY[1]},${pixelAtXY[2]},${pixelAtXY[3] / 255})`
        //        context.fillRect(x, y, 1, 1)
        //      }
        //    }
        //    userNickName = canvas
        //    resolve()
        //  }).catch(function (error) {
        //    console.error('oops, something went wrong!', error)
        //    reject(error)
        //  })
        //}))
        ps.push( //昵称
          new Promise((resolve, reject) => {
            this.htmlToSvgXml(`
                      <div style='width: 210px;padding:0 20px;font-size:24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center;'>
                        ${this.pano.user.nickName}
                      </div>
        `, 300, 40).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                userNickName = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        ps.push( //标题
          new Promise((resolve, reject) => {
            this.htmlToSvgXml(`
                      <div style='width: 960px;padding:20px;font-size:32px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'>
                        ${this.pano.title}
                      </div>
        `, 1000, 80).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                title = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        ps.push( //内容
          new Promise((resolve, reject) => {
            this.htmlToBase64(
              `<div style="width:500px;color:#666;font-size:24px;line-height:1.5;text-indent: 2em;text-align: justify;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;">${this.pano.description || ''}</div>`,
              600, 400
            ).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                description = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        Promise.all(ps).then(() => {
          var canvas = document.createElement('canvas')
          var context = canvas.getContext('2d')
          canvas.width = 1000
          canvas.height = 1350
          context.clearRect(0, 0, 1000, 1350)
          context.fillStyle = '#DDDDDD'
          context.fillRect(0, 0, 1000, 1350)

          context.drawImage(screenshot, 0, 0, 1000, 1000)

          context.save()
          context.drawImage(title, 0, 1000)
          context.restore()

          context.save()
          context.drawImage(qrCodeImg, 800, 1140, 150, 150)
          context.restore()

          context.save()
          context.fillStyle = '#000000'
          context.font = '18px sans-serif'
          context.textAlign = 'center'
          context.textBaseline = 'top'
          context.direction = 'ltr'
          context.fillText('长按识别打开全景', 875, 1300)
          context.restore()

          context.save()
          context.arc(50 + 75, 1140 + 75, 75, 0, Math.PI * 2)
          context.clip()
          context.drawImage(avatarImg, 50, 1140, 150, 150)
          context.restore()

          context.save()
          context.drawImage(userNickName, 0, 1140 + 150 + 20)
          context.restore()

          context.save()
          context.drawImage(description, 250, 1140 + 25)
          context.restore()

          //context.save()
          //context.fillStyle = '#000000'
          //context.font = '16px sans-serif'
          //context.textAlign = 'left'
          //context.textBaseline = 'top'
          //context.direction = 'ltr'
          //context.fillText(this.pano.title, 5, 513, 400)
          //context.restore()

          //context.save()
          //context.fillStyle = '#000000'
          //context.font = '16px sans-serif'
          //context.textAlign = 'left'
          //context.textBaseline = 'top'
          //context.direction = 'ltr'
          //context.fillText(this.pano.description, 110, 535, 300)
          //context.restore()

          //document.getElementById('screenshotBox').appendChild(userNickName)
          //document.getElementById('screenshotBox').appendChild(title)
          //document.getElementById('screenshotBox').appendChild(description)

          this.screenshotUrl = canvas.toDataURL()
          this.screenshotError = null
          //document.getElementById('screenshotBox').appendChild(canvas)
        }).catch((err) => {
          this.screenshotError = err
          this.screenshotUrl = qrurl
          console.error(err)
        })
      },
      showScreenshot() {
        var view = this.getView()
        var q = this.getFormatedQuery()
        q['view.fov'] = view.fov
        q['view.hlookat'] = view.hlookat
        q['view.vlookat'] = view.vlookat
        var url = `${location.protocol}//${location.host}${location.pathname}${this.getQuery(q)}`
        var qrurl = `${process.env.baseUrl}/Api/QRCode/?url=${encodeURIComponent(url)}&bColor=0x00FFFFFF&fColor=0xFF000000&level=3`
        var ps = []
        var screenshot, qrCodeImg, avatarImg
        ps.push(
          new Promise((resolve, reject) => {
            this.krpano_makeScreenshot((img) => {
              var fileReader = new FileReader()
              fileReader.onloadend = function (e) {
                var image = new Image()
                image.src = e.target.result
                image.onload = () => {
                  screenshot = image
                  resolve()
                }
              }
              fileReader.readAsDataURL(img)
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64(qrurl).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                qrCodeImg = image
                resolve()
              }
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64((this.pano.user.avatarUrl || (this.pano.user.avatar || {}).url || '/images/avatar-default.jpg').replace('/0/0/0/0/', '/200/200/1/0/')).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                avatarImg = image
                resolve()
              }
            })
          })
        )
        Promise.all(ps).then(() => {
          const btnp = 1100

          var canvas = document.createElement('canvas')
          var context = canvas.getContext('2d')
          canvas.width = 1000
          canvas.height = 1350
          context.clearRect(0, 0, 1000, 1350)
          context.fillStyle = '#DDDDDD'
          context.fillRect(0, 0, 1000, 1350)

          console.log('screenshot', screenshot)
          context.drawImage(screenshot, 0, 0, 1000, 1000)

          context.save()
          context.shadowColor = 'rgba(0, 0, 0, 1)'
          // 将阴影向右移动15px，向上移动10px
          context.shadowOffsetX = 0
          context.shadowOffsetY = 0
          // 轻微模糊阴影
          context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '32px 楷体'
          // 将文本填充为棕色
          context.fillStyle = '#ffffff'
          // 将文本设为居中对齐
          context.textAlign = 'left'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.title, 0, 1000, 960, 25)
          context.restore()

          context.save()
          context.drawImage(qrCodeImg, 800, btnp, 150, 150)
          context.restore()

          context.save()
          context.fillStyle = '#000000'
          context.font = '18px sans-serif'
          context.textAlign = 'center'
          context.textBaseline = 'top'
          context.direction = 'ltr'
          context.fillText('长按识别打开全景', 875, btnp + 170)
          context.restore()

          context.save()
          context.arc(50 + 75, btnp + 75, 75, 0, Math.PI * 2)
          context.clip()
          context.drawImage(avatarImg, 50, btnp, 150, 150)
          context.restore()

          context.save()
          //context.shadowColor = 'rgba(0, 0, 0, 1)'
          //// 将阴影向右移动15px，向上移动10px
          //context.shadowOffsetX = 0
          //context.shadowOffsetY = 0
          //// 轻微模糊阴影
          //context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '24px sans-serif'
          // 将文本填充为棕色
          context.fillStyle = '#000000'
          // 将文本设为居中对齐
          context.textAlign = 'center'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.user.nickName || this.pano.user.userName, 125, btnp + 150 + 25, 210, 25)
          context.restore()

          context.save()
          //context.shadowColor = 'rgba(0, 0, 0, 1)'
          //// 将阴影向右移动15px，向上移动10px
          //context.shadowOffsetX = 0
          //context.shadowOffsetY = 0
          //// 轻微模糊阴影
          //context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '24px sans-serif'
          // 将文本填充为棕色
          context.fillStyle = '#000000'
          // 将文本设为居中对齐
          context.textAlign = 'left'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.description, 250, btnp + 25, 500, 25, 8)
          context.restore()

          this.screenshotUrl = canvas.toDataURL()
          this.screenshotError = null
          //document.getElementById('screenshotBox').appendChild(canvas)
        }).catch((err) => {
          this.screenshotError = err
          this.screenshotUrl = qrurl
          console.error(err)
        })
      },
      wrapText(context, text, x, y, maxWidth, lineHeight, maxlines) {
        var words = []
        var word = ''
        if (typeof text != 'string') {
          console.log(text)
          return
        }
        var tests = text.split('')
        var reg = new RegExp('[\u4E00-\u9FA5]+')
        for (var i in tests) {
          if (reg.test(tests[i]) || tests[i] == ' ') {
            if (word != '') {
              words.push(word)
              word = ''
            }
            words.push(text[i])
          } else {
            word = word + tests[i]
          }
        }
        if (word != '') {
          words.push(word)
          word = ''
        }
        console.log(text, words)
        var line = ''
        var lineCount = 1
        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n]
          var metrics = context.measureText(testLine)
          var testWidth = metrics.width
          if (testWidth > maxWidth && n > 0) {
            if (maxlines && lineCount == maxlines) {
              line = line + '…'
              context.fillText(line, x, y)
              return
            }
            context.fillText(line, x, y)
            line = words[n]
            y += lineHeight
            lineCount++
          } else {
            line = testLine
          }
        }
        context.fillText(line, x, y)
      },
      makeScreenshot() {
        return new Promise((resolve, reject) => {
          this.krpano_makeScreenshot((img) => {
            resolve(img)
          })
        })
      },
      krpano_makeScreenshot(callback) {
        var f = this.getFunc({
          target: 'FullPano',
          name: 'makeScreenshot'
        })
        console.log(f)
        return f(callback, 1000, 1000)
      },
      getView() {
        return this.getFunc({
          target: 'FullPano',
          name: 'getView'
        })()
      },
      screenshotdrag(ev, id) {
        var el = ev.target
        var mel = document.getElementById(id)
        //var f = setInterval(() => {
        //  console.log(ev, { el })
        //}, 100)
        var func = (dragEv) => {
          //console.log(ev, dragEv)
        }
        el.addEventListener('drag', func)
        var funcend = (endev) => {
          //clearInterval(f)
          mel.style.left = (parseInt(mel.style.left, 10) + endev.screenX - ev.screenX) + 'px'
          mel.style.top = (parseInt(mel.style.top, 10) + endev.screenY - ev.screenY) + 'px'
          el.removeEventListener('drag', func)
          el.removeEventListener('dragend', funcend)
        }
        el.addEventListener('dragend', funcend)
        //ev.dataTransfer.setData('data', '123')
      },
      //#endregion
      getFormatedQuery() {
        var q = location.search.substring(1)
        var r = {}
        var l1 = q.split("&")
        for (var i in l1) {
          var l2 = l1[i].split("=")
          if (l2.length == 2) {
            r[l2[0]] = l2[1]
          }
        }
        return r
      },
      getQuery(fq) {
        var str = "?"
        for (var i in fq) {
          str += `${i}=${fq[i]}&`
        }
        return str.substring(0, str.length - 1)
      }
    },
  }
</script>
<style scoped>
</style>
